import React, { useState } from 'react';
import {
  DateText,
  IsReadIcon,
  MessageBlock,
  MessageBottomInfoBlock,
  MessageBottomLeftInfoBlock,
  MessageBottomRightInfoBlock,
  MessageSuperBlock,
  UnReadIcon,
  UserNameText,
  ButtonsContainer,
  Title,
} from './style';

import { IChatMessage } from '@/types';
import { formatTime } from '@/utils/format/datetime';
import { Done as DoneIcon, DoneAll as DoneAllIcon } from '@mui/icons-material';
import Button from '@/common/core/Button';
import { createCustomReminder, ignoreCustomReminderCreation } from '@/api/reminders';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { DialogActionsStyled } from './style';
import { format } from 'date-fns';
import { SharedReminderForm } from '../../ChatReminders/SharedReminderForm';
import { ReminderSchedule } from '@/types/reminders';

interface IChatMessagesListItemCreateCustomReminder {
  userIdFrom: string;
  userNameFrom: string;
  currentUserId: string;
  createdAtSeconds: number;
  toggleMenu: (e: React.MouseEvent<HTMLElement>) => void;
  anchorEl: HTMLElement | null;
  isViewed: boolean;
  messageBy: string;
  message: IChatMessage;
  onSend?: (text: string) => void;
  onReject?: () => void;
}

type DialogConfig = {
  title: string;
  content: string;
  confirmLabel: string;
  confirmColor: 'primary' | 'error';
  onConfirm: () => void | Promise<void>;
};

const ChatMessagesListItemCreateCustomReminder = ({
  userIdFrom,
  currentUserId,
  userNameFrom,
  createdAtSeconds,
  toggleMenu,
  anchorEl,
  isViewed,
  messageBy,
  message,
}: IChatMessagesListItemCreateCustomReminder) => {
  const REMINDER_TITLE = 'Set up a custom reminder';
  const [text, setText] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogConfig, setDialogConfig] = useState<DialogConfig | null>(null);
  const [isCompleted, setIsCompleted] = useState(message.isCompleted || false);
  const [isDeclined, setIsDeclined] = useState(message.isDeclined || false);
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [isRecurring, setIsRecurring] = useState(false);
  const [selectedTime, setSelectedTime] = useState<ReminderSchedule['nickname']>('morning');
  const [frequency, setFrequency] = useState<'daily' | 'weekly' | 'monthly'>('daily');

  const handleCreate = async () => {
    if (text.trim()) {
      try {
        const reminderData = {
          reminderText: text,
          dates: selectedDates,
          isRecurring,
          timeOfDay: selectedTime,
          frequency: isRecurring ? frequency : undefined,
          originalMessage: message,
        };

        await createCustomReminder({ ...reminderData });
        setIsCompleted(true);
      } catch (error) {
        console.error('Error creating the reminder:', error);
      }
    }
    setDialogOpen(false);
  };

  const handleIgnore = async () => {
    try {
      await ignoreCustomReminderCreation({
        ...message,
      });
      setIsDeclined(true);
    } catch (error) {
      console.error('Error ignoring reminder message:', error);
    }
    setDialogOpen(false);
  };

  const openConfirmDialog = (config: DialogConfig) => {
    setDialogConfig(config);
    setDialogOpen(true);
  };

  const handleDateChange = (newDate: Date | null) => {
    if (newDate) {
      if (isRecurring) {
        setSelectedDates([newDate]);
      } else {
        if (
          !selectedDates.some(
            (date) => format(date, 'yyyy-MM-dd') === format(newDate, 'yyyy-MM-dd'),
          )
        ) {
          setSelectedDates([...selectedDates, newDate]);
        }
      }
    }
  };

  const renderContent = () => {
    if (isCompleted) {
      return (
        <>
          <Title>{REMINDER_TITLE}</Title>
          <ButtonsContainer>
            <Button color="primary" disabled fullWidth>
              Reminder set
            </Button>
          </ButtonsContainer>
        </>
      );
    }

    if (isDeclined) {
      return (
        <>
          <Title>{REMINDER_TITLE}</Title>
          <ButtonsContainer>
            <Button color="error" disabled fullWidth>
              Reminder ignored
            </Button>
          </ButtonsContainer>
        </>
      );
    }

    const buttons = (
      <>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            openConfirmDialog({
              title: 'Create Custom Reminder',
              content: 'Are you sure you want to create this custom reminder?',
              confirmLabel: 'Create',
              confirmColor: 'primary',
              onConfirm: handleCreate,
            })
          }
        >
          Create
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() =>
            openConfirmDialog({
              title: 'Ignore Custom Reminder Creation',
              content: 'Are you sure you want to ignore the creation of this reminder?',
              confirmLabel: 'Ignore',
              confirmColor: 'error',
              onConfirm: handleIgnore,
            })
          }
        >
          Ignore
        </Button>
      </>
    );

    return (
      <SharedReminderForm
        title={REMINDER_TITLE}
        text={text}
        selectedDates={selectedDates}
        isRecurring={isRecurring}
        selectedTime={selectedTime}
        placeholder={`Write the reminder message based on the message you received from the client:\n\n${message.body}`}
        onTextChange={setText}
        onDateChange={handleDateChange}
        onRemoveDate={(date) =>
          setSelectedDates((dates) =>
            dates.filter((d) => format(d, 'yyyy-MM-dd') !== format(date, 'yyyy-MM-dd')),
          )
        }
        onRecurringChange={(newIsRecurring) => {
          setIsRecurring(newIsRecurring);
          if (newIsRecurring && selectedDates.length > 1) {
            setSelectedDates([selectedDates[0]]);
          }
        }}
        onTimeChange={setSelectedTime}
        buttons={buttons}
        frequency={frequency}
        onFrequencyChange={setFrequency}
      />
    );
  };

  return (
    <MessageSuperBlock>
      <MessageBlock
        isCurrentUser={userIdFrom === currentUserId}
        anchorEl={anchorEl}
        onClick={toggleMenu}
      >
        {renderContent()}
        <MessageBottomInfoBlock>
          <MessageBottomLeftInfoBlock>
            <UserNameText isCurrentUser={userIdFrom === currentUserId}>{messageBy}</UserNameText>
          </MessageBottomLeftInfoBlock>
          <MessageBottomRightInfoBlock>
            <DateText isCurrentUser={userIdFrom === currentUserId}>
              {formatTime(createdAtSeconds * 1000)}
            </DateText>
            {userIdFrom !== currentUserId && (
              <>{isViewed ? <IsReadIcon as={DoneAllIcon} /> : <UnReadIcon as={DoneIcon} />}</>
            )}
          </MessageBottomRightInfoBlock>
        </MessageBottomInfoBlock>
      </MessageBlock>

      {dialogConfig && (
        <Dialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          aria-labelledby="confirmation-dialog-title"
          aria-describedby="confirmation-dialog-description"
        >
          <DialogTitle id="confirmation-dialog-title">{dialogConfig.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="confirmation-dialog-description">
              {dialogConfig.content}
            </DialogContentText>
          </DialogContent>
          <DialogActionsStyled>
            <Button
              onClick={dialogConfig.onConfirm}
              color="primary" // Keep primary color for main action
              variant="contained"
              autoFocus
            >
              {dialogConfig.confirmLabel}
            </Button>
            <Button
              onClick={() => setDialogOpen(false)}
              color="error" // Changed to error (red)
            >
              Cancel
            </Button>
          </DialogActionsStyled>
        </Dialog>
      )}
    </MessageSuperBlock>
  );
};

export default ChatMessagesListItemCreateCustomReminder;
