import { Dialog, DialogContent } from '@mui/material';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@/store';
import { updateReminder } from '@/store/customers/remindersSlice';
import { ReminderForm } from './ReminderForm';
import { Reminder, ReminderSchedule } from '@/types/reminders';
import { Timestamp } from 'firebase/firestore';

interface EditReminderDialogProps {
  reminder: Reminder;
  userId: string;
  onClose: () => void;
}

export const EditReminderDialog = ({ reminder, userId, onClose }: EditReminderDialogProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const handleSubmit = async (formData: {
    content: string;
    dates: Date[];
    isRecurring: boolean;
    schedule: {
      timeOfDay: ReminderSchedule['nickname'];
      frequency?: 'daily' | 'weekly' | 'monthly';
    };
  }) => {
    try {
      await dispatch(
        updateReminder({
          userId,
          reminderId: reminder.id!,
          updates: {
            content: formData.content,
            schedule: {
              ...reminder.schedule,
              sendAtUtc: Timestamp.fromDate(formData.dates[0]),
              repeating: formData.isRecurring,
              nickname: formData.schedule.timeOfDay,
              frequency: formData.schedule.frequency,
            },
          },
        }),
      ).unwrap();
      onClose();
    } catch (error) {
      console.error('Failed to update reminder:', error);
    }
  };

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <ReminderForm
          title="Edit Reminder"
          initialData={{
            content: reminder.content,
            dates: [reminder.schedule.sendAtUtc?.toDate() ?? new Date()],
            isRecurring: reminder.schedule.repeating,
            schedule: {
              timeOfDay: reminder.schedule.nickname,
              frequency: reminder.schedule.frequency,
            },
          }}
          onSubmit={handleSubmit}
          onCancel={onClose}
          submitLabel="Save Changes"
          cancelLabel="Cancel"
        />
      </DialogContent>
    </Dialog>
  );
};
