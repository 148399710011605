import { useEffect, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Alert } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useChatMessages } from '@/hooks/chat/useChatMessages';
import {
  AttachmentBlock,
  CenterAddMessageBlock,
  CenterBlock,
  CenterMainWrapperBlock,
  CenterMessagesAndTagsBlock,
  CenterMessagesBlock,
  CenterMessagesContentBlock,
  CenterMessagesWrapperBlock,
  CenterProgramBlock,
  CenterTopBlock,
  CloseBtn,
  Container,
  RightFAQBlock,
  RightTagsBlock,
} from './styles';
import { EChatPageType, IChat } from '@/types';
import DrawerMyPlan from '../ChatDrawer/DrawerMyPlan';
import { DrawerCopilot } from '../ChatDrawer/DrawerCopilot';
import DrawerFeedbackProgress from '../ChatDrawer/DrawerFeedbackProgress';
import DrawerFaq from '../ChatDrawer/DrawerFaq';
import ChatProgram from '../ChatProgram';
import ChatUserProfile from '../ChatUserProfile';
import ChatSurveys from '../ChatSurveys';
import ShareCategory from '../ChatAddMessage/ChatResourcesCategoryShare';
import ResourcesContainer from '../ChatAddMessage/ChatResourcesContainer';
import ChatShareHandouts from '../ChatAddMessage/ChatShareHandouts';
import ChatNavigation from '../ChatNavigation';
import ChatMessages from '../ChatMessages';
import ChatMessageTags from '../ChatMessageTags';
import ChatReplyMessage from '../ChatAddMessage/ChatReplyMessage';
import ChatEditMessage from '../ChatAddMessage/ChatEditMessage';
import ChatAddMessage from '../ChatAddMessage';
import { setCoachWatchingCustomer } from '@/store/customers/watcherSlice';
import DrawerClientInfo from '../ChatDrawer/DrawerClientInfo';
import { setDrawerOpen } from '@/store/coPilot/coPilotSlice';
import { resetFeedback } from '@/store/customers/progressFeedbackSlice';
import ChatBanner from '../ChatBanner';
import { EBannerType } from '@fitmate-coach/fitmate-types';
import ChatSavvyData from '../ChatSavvyData';
import { resetMessages } from '@/store/automation/aiSlice';
import ChatReminders from '../ChatReminders';

const ChatContent = ({
  pageType,
  handlePageType,
  files,
  handleChangeFiles,
  handleDeleteFile,
  messageEdited,
  messageFileEdited,
  handleMessageEdited,
  handleMessageFileEdited,
}: IChat) => {
  const dispatch = useAppDispatch();
  const [isInputMessageExpanded, setIsInputMessageExpanded] = useState<boolean>(true);
  const [isOpenResoucesBlock, setIsOpenResoucesBlock] = useState<boolean>(false);
  const [isOpenCategoryBlock, setIsOpenCategoryBlock] = useState<boolean>(false);
  const [isOpenHandoutBlock, setIsOpenHandoutBlock] = useState<boolean>(false);
  const [isSearchValueFromMessageUpdated, setIsSearchValueFromMessageUpdated] =
    useState<boolean>(false);
  const [messageIdTagsCurrent, setMessageIdTagsCurrent] = useState<string | null>(null);
  const [answerFAQChosen, setAnswerFAQChosen] = useState<string>('');
  const { customer } = useAppSelector((state) => state.customerInfos);
  const haveCoachAssigned = customer?.coachAssignment?.team !== undefined;
  const { message } = useAppSelector((state) => state.progressFeedback);
  const { needEditing } = useAppSelector((state) => state.messageAnalyze);
  const { coachData } = useAppSelector((state) => state.coachAuth);
  const { firstTimeMessageWarning } = useAppSelector((state) => state.sendAs);
  const { openedDrawer } = useAppSelector((state) => state.coPilot);

  const messages = useChatMessages(customer?.id);
  const refDivContainer = useRef<any>();

  useEffect(() => {
    // close any open draws on page load
    dispatch(setDrawerOpen(false));
    dispatch(resetFeedback());
    dispatch(resetMessages());
  }, []);

  useEffect(() => {
    handleIsInputMessageExpanded(true);
    setMessageIdTagsCurrent(null);
  }, [customer, pageType]);

  useEffect(() => {
    if (isSearchValueFromMessageUpdated) {
      const timer = setTimeout(() => {
        setIsSearchValueFromMessageUpdated(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [isSearchValueFromMessageUpdated]);

  useEffect(() => {
    if (customer?.id) {
      dispatch(setCoachWatchingCustomer({ userId: customer.id, coachId: coachData?.id }));
    }
  }, [customer]);

  const handleMessageIdTagsCurrent = (value: string | null) => {
    setMessageIdTagsCurrent(value);
  };

  const handleOpenResoucesBlock = () => {
    setIsOpenResoucesBlock((prev) => !prev);
    setIsOpenCategoryBlock(false);
    setIsOpenHandoutBlock(false);
    setIsInputMessageExpanded(false);
  };

  const handleOpenCategoryBlock = () => {
    setIsOpenCategoryBlock((prev) => !prev);
    setIsOpenResoucesBlock(false);
    setIsOpenHandoutBlock(false);
    setIsInputMessageExpanded(false);
  };

  const handleOpenHandoutBlock = () => {
    setIsOpenHandoutBlock((prev) => !prev);
    setIsOpenCategoryBlock(false);
    setIsOpenResoucesBlock(false);
    setIsInputMessageExpanded(false);
  };

  const handleIsInputMessageExpanded = (value: boolean) => {
    setIsInputMessageExpanded(value);
    if (value) {
      setIsOpenCategoryBlock(false);
      setIsOpenResoucesBlock(false);
      setIsOpenHandoutBlock(false);
    }
  };

  const handleAnswerFAQChosen = (value: string) => {
    setAnswerFAQChosen(value);
  };

  const onScrollChat = () => messages.bottom();
  useEffect(() => messages.bottom(), [pageType]);

  const validBannerTypes = [EBannerType.ONBOARDING, EBannerType.ACTIVE_EOW, EBannerType.DAY_OF_EOW];

  return (
    <Container>
      <CenterBlock>
        <>
          <CenterTopBlock
            isBannerShown={customer?.showBanner && validBannerTypes.includes(customer?.showBanner)}
          >
            <ChatNavigation
              pageType={pageType}
              handlePageType={handlePageType}
              isCopilotOpen={openedDrawer}
              handleIsCopilotOpen={() => dispatch(setDrawerOpen(!openedDrawer))}
            />
            <ChatBanner />
          </CenterTopBlock>
          {pageType === EChatPageType.CONVERSATION && (
            <CenterMainWrapperBlock>
              <CenterMessagesWrapperBlock
                isToggleApiToken={!!coachData?.toggleApiToken}
                isBannerShown={
                  customer?.showBanner && validBannerTypes.includes(customer?.showBanner)
                }
                ref={refDivContainer}
              >
                <>
                  <CenterMessagesAndTagsBlock>
                    <CenterMessagesBlock
                      isOpenBlock={isOpenResoucesBlock || isOpenCategoryBlock}
                      ref={messages.ref}
                    >
                      <CenterMessagesContentBlock>
                        {customer?.id && messages && (
                          <ChatMessages
                            currentUserId={customer.id}
                            currentUserMessagesList={messages.docs}
                            handleMessageEdited={handleMessageEdited}
                            handleMessageFileEdited={handleMessageFileEdited}
                            messageIdTagsCurrent={messageIdTagsCurrent}
                            handleMessageIdTagsCurrent={handleMessageIdTagsCurrent}
                          />
                        )}
                      </CenterMessagesContentBlock>
                    </CenterMessagesBlock>
                    {messageIdTagsCurrent !== null && (
                      <RightTagsBlock>
                        <ChatMessageTags
                          messageIdTagsCurrent={messageIdTagsCurrent}
                          handleMessageIdTagsCurrent={handleMessageIdTagsCurrent}
                        />
                      </RightTagsBlock>
                    )}
                  </CenterMessagesAndTagsBlock>

                  <ChatReplyMessage />

                  {messageEdited !== null && (
                    <ChatEditMessage
                      messageEdited={messageEdited}
                      handleMessageEdited={handleMessageEdited}
                    />
                  )}

                  <CenterAddMessageBlock>
                    {firstTimeMessageWarning && (
                      <Alert severity="error">
                        You are sending a message <b>as a supportive coach</b> to this client for
                        the first time. Please remember to introduce yourself first.
                      </Alert>
                    )}

                    {needEditing && (
                      <Alert severity="error">
                        You need to edit placeholder text before sending a message.
                      </Alert>
                    )}

                    {customer && (
                      <ChatAddMessage
                        files={files}
                        handleChangeFiles={handleChangeFiles}
                        handleDeleteFile={handleDeleteFile}
                        messageEdited={messageEdited}
                        messageFileEdited={messageFileEdited}
                        handleMessageEdited={handleMessageEdited}
                        handleMessageFileEdited={handleMessageFileEdited}
                        handleOpenResoucesBlock={handleOpenResoucesBlock}
                        handleOpenCategoryBlock={handleOpenCategoryBlock}
                        handleOpenHandoutBlock={handleOpenHandoutBlock}
                        answerFAQChosen={answerFAQChosen}
                        handleAnswerFAQChosen={handleAnswerFAQChosen}
                        isInputMessageExpanded={isInputMessageExpanded}
                        handleIsInputMessageExpanded={handleIsInputMessageExpanded}
                        onScrollChat={onScrollChat}
                        parentHeight={refDivContainer?.current?.clientHeight}
                      />
                    )}
                  </CenterAddMessageBlock>

                  {pageType === EChatPageType.CONVERSATION && isOpenResoucesBlock && (
                    <AttachmentBlock>
                      <CloseBtn aria-label="close" onClick={handleOpenResoucesBlock} size="large">
                        <CloseIcon />
                      </CloseBtn>
                      <ResourcesContainer handleOpenResoucesBlock={handleOpenResoucesBlock} />
                    </AttachmentBlock>
                  )}
                  {pageType === EChatPageType.CONVERSATION && isOpenCategoryBlock && (
                    <AttachmentBlock>
                      <CloseBtn aria-label="close" onClick={handleOpenCategoryBlock} size="large">
                        <CloseIcon />
                      </CloseBtn>
                      <ShareCategory handleOpenCategoryBlock={handleOpenCategoryBlock} />
                    </AttachmentBlock>
                  )}
                  {pageType === EChatPageType.CONVERSATION && isOpenHandoutBlock && (
                    <AttachmentBlock>
                      <CloseBtn aria-label="close" onClick={handleOpenHandoutBlock} size="large">
                        <CloseIcon />
                      </CloseBtn>
                      <ChatShareHandouts handleOpenHandoutBlock={handleOpenHandoutBlock} />
                    </AttachmentBlock>
                  )}
                </>
              </CenterMessagesWrapperBlock>
            </CenterMainWrapperBlock>
          )}
          {pageType === EChatPageType.PROGRAM && (
            <CenterProgramBlock>
              <ChatProgram />
            </CenterProgramBlock>
          )}
          {pageType === EChatPageType.USER_PROFILE && (
            <CenterProgramBlock>
              <ChatUserProfile />
            </CenterProgramBlock>
          )}
          {pageType === EChatPageType.SURVEY && customer?.id && (
            <CenterProgramBlock>
              <ChatSurveys currentUserId={customer.id} />
            </CenterProgramBlock>
          )}

          {pageType === EChatPageType.SAVVY_DATA && (
            <CenterProgramBlock>
              <ChatSavvyData />
            </CenterProgramBlock>
          )}
          {pageType === EChatPageType.REMINDERS && (
            <CenterProgramBlock>
              <ChatReminders />
            </CenterProgramBlock>
          )}
        </>
      </CenterBlock>

      <DrawerMyPlan />
      {message && (
        <RightFAQBlock>
          <DrawerFeedbackProgress />
        </RightFAQBlock>
      )}
      <DrawerClientInfo />
      <DrawerFaq />
      {haveCoachAssigned && customer?.id && <DrawerCopilot clientId={customer.id} />}
    </Container>
  );
};

export default ChatContent;
