import { styled, Theme } from '@mui/system';
import { Typography } from '@mui/material';
import { DialogActions } from '@mui/material';

export const MessageSuperBlock = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '10px 0px 0px 0px',
}));

export const DateTextLeft = {
  color: 'rgba(15, 15, 33, 0.4) !important',
};

export const DateTextRight = {
  color: '#96BCB4 !important',
};

export const MessageLeftBlock = {
  backgroundColor: 'rgba(197, 197, 254, 0.16)',
  marginLeft: 24,
  borderRadius: '12px 12px 12px 0px',
};

export const MessageRightBlock = {
  backgroundColor: '#D6F5EE',
  marginRight: 24,
  borderRadius: '12px 12px 0px 12px',
};

export const MessageBlock = styled('div')<{ isCurrentUser: boolean; anchorEl: any }>(
  ({ isCurrentUser, anchorEl }) => ({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 419,
    minWidth: 254,
    padding: '12px 12px 12px 12px',
    ...(isCurrentUser ? MessageLeftBlock : MessageRightBlock),
  }),
);

export const BodyText = styled(Typography)(({ theme }: { theme: Theme }) => ({
  fontSize: 14,
  lineHeight: '18px',
  color: '#0F0F21',
  maxWidth: '450px !important',
  wordWrap: 'break-word',
}));

export const MessageBottomInfoBlock = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  paddingTop: 6,
}));

export const MessageBottomLeftInfoBlock = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexGrow: 1,
}));

export const MessageBottomRightInfoBlock = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  flexGrow: 0,
}));

export const UserNameText = styled('div')<{ isCurrentUser: boolean }>(({ isCurrentUser }) => ({
  fontSize: 12,
  fontWeight: 400,
  lineHeight: '16px',
  width: 163,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  ...(isCurrentUser ? DateTextLeft : DateTextRight),
}));

export const DateText = styled('div')<{ isCurrentUser: boolean }>(({ isCurrentUser }) => ({
  fontSize: 12,
  fontWeight: 400,
  lineHeight: '16px',
  ...(isCurrentUser ? DateTextLeft : DateTextRight),
}));

export const ReadIcon = styled('div')(({ theme }: { theme: Theme }) => ({
  fontSize: 12,
  padding: 0,
  margin: 0,
  marginLeft: 2,
}));

export const UnReadIcon = styled('div')(({ theme }: { theme: Theme }) => ({
  ...ReadIcon,
  color: '#96BCB4',
}));

export const IsReadIcon = styled('div')(({ theme }: { theme: Theme }) => ({
  ...ReadIcon,
  color: '#47B29A',
}));

export const ButtonsContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  gap: '8px',
  justifyContent: 'flex-end',
}));

export const MessageTextArea = styled('textarea')(({ theme }: { theme: Theme }) => ({
  width: '100%',
  minHeight: '175px',
  padding: '8px',
  fontSize: '14px',
  lineHeight: '18px',
  color: '#0F0F21',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  border: '1px solid #E4E7EC',
  borderRadius: '4px',
  resize: 'vertical',
  '&:focus': {
    outline: 'none',
    borderColor: '#47B29A',
  },
  '&:disabled': {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    cursor: 'not-allowed',
    border: '1px solid #E4E7EC',
  },
}));

export const DialogButtonsContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  width: '100%',
  '& .MuiButton-root': {
    maxWidth: '200px',
    width: '100%',
    margin: '0 auto',
  },
}));

export const DialogActionsStyled = styled(DialogActions)(({ theme }: { theme: Theme }) => ({
  justifyContent: 'center',
  padding: '16px',
  flexDirection: 'column',
  gap: '8px',
}));

export const Title = styled('h3')(({ theme }: { theme: Theme }) => ({
  textAlign: 'center',
  margin: '0 0 8px 0',
  color: theme.palette.text.primary,
  fontWeight: 600,
}));

export const TextAreaLabel = styled('div')(({ theme }: { theme: Theme }) => ({
  fontSize: '14px',
  color: theme.palette.text.secondary,
  marginBottom: '4px',
}));

export const DatePickerContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  marginBottom: '12px',
  width: '100%',
}));

export const SelectedDatesContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '8px',
  marginTop: '8px',
}));

export const DateChip = styled('div')(({ theme }: { theme: Theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.contrastText,
  padding: '4px 8px',
  borderRadius: '16px',
  fontSize: '12px',
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
}));

export const RecurringContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  marginBottom: '12px',
}));

export const RecurringLabel = styled('div')(({ theme }: { theme: Theme }) => ({
  fontSize: '14px',
  color: theme.palette.text.secondary,
}));

export const TimeSelectContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  marginBottom: '12px',
  width: '100%',
}));
