import React, { useState } from 'react';
import { Timestamp } from '@firebase/firestore';
import {
  Reply as ReplyIcon,
  FileCopy as FileCopyIcon,
  Delete as DeleteIcon,
  Done as DoneIcon,
  DoneAll as DoneAllIcon,
} from '@mui/icons-material';
import remarkGfm from 'remark-gfm';
import Menu from '@/common/core/Menu';
import { useAppDispatch } from '@/hooks';
import { formatTime } from '@/utils/format/datetime';
import { useChatMessageDelete } from '@/hooks/chat/useChatMessageDelete';
import { addQuotedMessage } from '@/store/chat/quotedMessageSlice';
import { initFeedbackOnMessage } from '@/store/customers/progressFeedbackSlice';
import {
  ActionTrackingDetails,
  ActionTrackingGoal,
  BodyText,
  DateText,
  FileImagePreviewReply,
  IsReadIcon,
  MessageBlock,
  MessageBottomInfoBlock,
  MessageBottomLeftInfoBlock,
  MessageBottomRightInfoBlock,
  MessageContainer,
  MessageSuperBlock,
  QuoteBlock,
  QuoteBodyText,
  QuoteLine,
  QuoteLineBlock,
  QuoteMessageBlock,
  QuoteName,
  QuoteNameBlock,
  StyledReactMarkDown,
  SubMenu,
  SubMenuDelete,
  UnReadIcon,
  UserNameText,
} from './styles';
import { EChatMessageAnalysisType, IChatMessagesListItem, IChatMessage } from '@/types';
import ChatMessagesListItemFiles from '../ChatMessagesListItemFiles';
import ChatMessagesListItemReference from '../ChatMessagesListItemReference';
import ChatMessagesListItemActionProgram from '../ChatMessagesListItemActionProgram';
import ChatMessagesListItemSendReminder from '../ChatMessagesListSendReminder';
import ChatMessagesListItemTypeAnalysis from '../ChatMessagesListItemTypeAnalysis';
import ChatMessagesListItemCreateCustomReminder from '../ChatMessagesListItemCreateCustomReminder';
import { cleanMessageAnalysis } from '@/utils/data/message';
import { handleCopyMessageId, handleCopyToClipboard } from '@/utils/tools/copyToClipboard';

const ChatMessagesListItem = ({
  message,
  currentUserId,
  handleMessageFileEdited,
  messageIdTagsCurrent,
  handleMessageIdTagsCurrent,
}: // handleMessageEdited,
IChatMessagesListItem) => {
  const {
    id: messageId,
    userIdFrom,
    userNameFrom,
    body,
    files,
    replyToMessage,
    references,
    isViewed,
    actionTracking,
    reminders,
  } = message;
  const cleanType = cleanMessageAnalysis(message);
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const deleteMessage = useChatMessageDelete();
  const createdAt = message.createdAt || Timestamp.now();

  const messageBy = (() => {
    if (message.virtualUserIdFrom === message.userIdFrom) return `${message.userNameFrom}`;
    if (!message.virtualUserNameFrom) return `${message.userNameFrom}`;
    return `${message.virtualUserNameFrom} (${message.userNameFrom})`;
  })();

  const toggleMenu = (e: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleReplyMessage = () => {
    setAnchorEl(null);
    dispatch(
      addQuotedMessage({
        id: messageId,
        body: body ? body : '',
        files,
        userId: currentUserId,
        userIdFrom: message.virtualUserIdFrom || userIdFrom,
        userNameFrom: message.virtualUserNameFrom || userNameFrom,
        ...(actionTracking && { actionTracking: actionTracking }),
      }),
    );
  };

  const handleDeleteMessage = () => {
    deleteMessage(messageId).then(() => {
      setAnchorEl(null);
    });
  };

  const handleFeedBackOnProgress = () => {
    dispatch(
      addQuotedMessage({
        id: message.id,
        body: message.body,
        files: [],
        userId: message.userId,
        userIdFrom: message.virtualUserIdFrom,
        userNameFrom: message.virtualUserNameFrom,
      }),
    );
    dispatch(initFeedbackOnMessage({ message }));
  };

  const formatMessage = (body: string) => {
    return body.replace(/\d{1,}\./, (match) => {
      return `${match.slice(0, -1)}\\.`;
    });
  };

  const menuBuilder = () => {
    if (reminders) {
      return;
    }

    if (actionTracking) {
      return (
        <Menu anchorEl={anchorEl} toggleMenu={toggleMenu}>
          <SubMenu onClick={() => handleCopyMessageId(messageId)}>Message ID</SubMenu>
        </Menu>
      );
    } else if (!references) {
      return (
        <Menu anchorEl={anchorEl} toggleMenu={toggleMenu}>
          <SubMenu onClick={handleFeedBackOnProgress}>
            Give feedback <br />
            or answer question
          </SubMenu>
          <SubMenu onClick={handleReplyMessage}>
            Reply
            <ReplyIcon />
          </SubMenu>
          <SubMenu onClick={() => handleCopyToClipboard(body)}>
            Copy
            <FileCopyIcon />
          </SubMenu>
          <SubMenuDelete onClick={handleDeleteMessage}>
            Delete
            <DeleteIcon />
          </SubMenuDelete>
          <SubMenu onClick={() => handleCopyMessageId(messageId)}>Message ID</SubMenu>
        </Menu>
      );
    } else {
      return (
        <Menu anchorEl={anchorEl} toggleMenu={toggleMenu}>
          {userIdFrom !== currentUserId && (
            <SubMenuDelete onClick={handleDeleteMessage}>
              Delete
              <DeleteIcon />
            </SubMenuDelete>
          )}
          <SubMenu onClick={() => handleCopyMessageId(messageId)}>Message ID</SubMenu>
        </Menu>
      );
    }
  };

  return (
    <>
      <MessageContainer isCurrentUser={userIdFrom === currentUserId}>
        {body && !reminders && (
          <MessageSuperBlock>
            <MessageBlock
              isCurrentUser={userIdFrom === currentUserId}
              isCurrentMessage={messageId === messageIdTagsCurrent}
              anchorEl={anchorEl}
              onClick={
                messageIdTagsCurrent !== null
                  ? () => handleMessageIdTagsCurrent(messageId)
                  : toggleMenu
              }
            >
              {replyToMessage && (
                <QuoteBlock>
                  <QuoteLineBlock>
                    <QuoteLine />
                  </QuoteLineBlock>
                  <QuoteMessageBlock>
                    <QuoteBodyText>
                      <BodyText as="div">
                        {replyToMessage.actionTracking ? (
                          <div>
                            <ActionTrackingGoal>
                              {replyToMessage.actionTracking.goal}
                            </ActionTrackingGoal>
                            <ActionTrackingDetails>
                              {replyToMessage.actionTracking.details}
                            </ActionTrackingDetails>
                          </div>
                        ) : (
                          <QuoteNameBlock>
                            <QuoteName>{replyToMessage?.userNameFrom}</QuoteName>
                          </QuoteNameBlock>
                        )}

                        <StyledReactMarkDown
                          children={replyToMessage?.body || ''}
                          remarkPlugins={[remarkGfm]}
                        />
                        {replyToMessage?.files?.[0]?.url && (
                          <FileImagePreviewReply src={replyToMessage?.files?.[0]?.url} alt="" />
                        )}
                      </BodyText>
                    </QuoteBodyText>
                  </QuoteMessageBlock>
                </QuoteBlock>
              )}

              <BodyText as="div">
                <StyledReactMarkDown
                  children={formatMessage(body)}
                  remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
                />
              </BodyText>
              <MessageBottomInfoBlock>
                <MessageBottomLeftInfoBlock>
                  <UserNameText isCurrentUser={userIdFrom === currentUserId}>
                    {messageBy}
                  </UserNameText>
                </MessageBottomLeftInfoBlock>
                <MessageBottomRightInfoBlock>
                  <DateText isCurrentUser={userIdFrom === currentUserId}>
                    {formatTime(createdAt?.seconds * 1000)}
                  </DateText>
                  {userIdFrom !== currentUserId && (
                    <>{isViewed ? <IsReadIcon as={DoneAllIcon} /> : <UnReadIcon as={DoneIcon} />}</>
                  )}
                </MessageBottomRightInfoBlock>
              </MessageBottomInfoBlock>
              {cleanType !== EChatMessageAnalysisType.DEFAULT &&
                !(
                  Array.isArray(cleanType) &&
                  cleanType.length === 1 &&
                  cleanType[0] === EChatMessageAnalysisType.DEFAULT
                ) && (
                  <MessageBottomInfoBlock>
                    <ChatMessagesListItemTypeAnalysis message={message} />
                  </MessageBottomInfoBlock>
                )}
            </MessageBlock>
          </MessageSuperBlock>
        )}
        {files?.length !== 0 && (
          <ChatMessagesListItemFiles
            messageId={messageId}
            userIdFrom={userIdFrom}
            userNameFrom={userNameFrom}
            createdAtSeconds={createdAt?.seconds}
            currentUserId={currentUserId}
            files={files}
            handleMessageFileEdited={handleMessageFileEdited}
            isViewed={isViewed ?? false}
            messageBy={messageBy}
            showReply={!body}
            replyToMessage={replyToMessage}
          />
        )}
        {references?.length !== 0 && (
          <ChatMessagesListItemReference
            references={references}
            userIdFrom={userIdFrom}
            userNameFrom={userNameFrom}
            currentUserId={currentUserId}
            createdAtSeconds={createdAt?.seconds}
            toggleMenu={toggleMenu}
            anchorEl={anchorEl}
            isViewed={isViewed ?? false}
            messageBy={messageBy}
          />
        )}
        {!!actionTracking && (
          <ChatMessagesListItemActionProgram
            userIdFrom={userIdFrom}
            userNameFrom={userNameFrom}
            currentUserId={currentUserId}
            createdAtSeconds={createdAt?.seconds}
            toggleMenu={toggleMenu}
            anchorEl={anchorEl}
            isViewed={isViewed ?? false}
            actionTracking={actionTracking}
            messageBy={messageBy}
            message={message}
          />
        )}

        {reminders && reminders.length > 0 && !reminders.includes('not_yet_created') && (
          <ChatMessagesListItemSendReminder
            userIdFrom={userIdFrom}
            userNameFrom={userNameFrom}
            currentUserId={currentUserId}
            createdAtSeconds={createdAt?.seconds}
            toggleMenu={toggleMenu}
            anchorEl={anchorEl}
            isViewed={isViewed ?? false}
            messageBy={messageBy}
            message={message}
          />
        )}

        {reminders && reminders.length > 0 && reminders.includes('not_yet_created') && (
          <ChatMessagesListItemCreateCustomReminder
            userIdFrom={userIdFrom}
            userNameFrom={userNameFrom}
            currentUserId={currentUserId}
            createdAtSeconds={createdAt?.seconds}
            toggleMenu={toggleMenu}
            anchorEl={anchorEl}
            isViewed={isViewed ?? false}
            messageBy={messageBy}
            message={message}
          />
        )}
      </MessageContainer>
      {menuBuilder()}
    </>
  );
};

export default ChatMessagesListItem;
