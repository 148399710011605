import { getTeamRef, getUserRef } from '@/api/firestores';
import type { AppDispatch, RootState } from '@/store';
import { TCoache } from '@/types';
import { db } from '@/utils/firebase/firebaseInit';
import { collection, getDoc, getDocs, query, where } from '@firebase/firestore';
import { ClientUser, ITeamData } from '@fitmate-coach/fitmate-types';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'sonner';

const initialState = {
  loading: true,
  coaches: [] as TCoache[],
  selected: null as TCoache | null,
  firstTimeMessageWarning: false,
};

export const sendAsSlice = createSlice({
  name: 'sendAs',
  initialState,
  reducers: {
    selectSender: (state, action: PayloadAction<TCoache>) => {
      state.selected = action.payload;
    },
    resetFirstTimeMessageWarning: (state) => {
      state.firstTimeMessageWarning = false;
    },
    resetSendAs: (state) => {
      state.selected = null;
      state.coaches = [];
      state.firstTimeMessageWarning = false;
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendasInit.pending, (state) => {
        state.loading = true;
        state.firstTimeMessageWarning = false;
        state.selected = null;
        state.coaches = [];
      })
      .addCase(sendasInit.fulfilled, (state, action) => {
        state.firstTimeMessageWarning = false;
        if (!action.payload) {
          state.loading = false;
          return;
        }
        if (!action.payload.team) {
          toast.error('No team found for this customer');
          state.loading = false;
          return;
        }
        state.coaches = [
          { ...action.payload.team.mainCoach, main: true },
          ...action.payload.team.supportiveCoaches.map((coach) => ({ ...coach, main: false })),
        ];
        state.selected = state.coaches[0];
        state.loading = false;
      })
      .addCase(checkFirstTimeMessageWarning.fulfilled, (state, action) => {
        state.firstTimeMessageWarning = action.payload === 0;
      });
  },
});

export const { selectSender, resetFirstTimeMessageWarning, resetSendAs } = sendAsSlice.actions;
export default sendAsSlice.reducer;

const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

export const sendasInit = createAppAsyncThunk<
  { team?: ITeamData } | undefined,
  { clientUser: string }
>('sendas/init', async (arg) => {
  const clientUser = await getDoc(getUserRef(arg.clientUser));
  const teamId = (clientUser.data() as ClientUser)?.coachAssignment?.team;
  if (!teamId) return undefined;
  const team = await getDoc(getTeamRef(teamId));
  if (!team.exists()) return undefined;
  return { team: team.data() as ITeamData };
});

export const checkFirstTimeMessageWarning = createAsyncThunk(
  'sendas/checkFirstTimeMessageWarning',
  async (args: { userId: string; userIdFrom: string }) => {
    try {
      const ref = collection(db, 'messages');
      const q = query(
        ref,
        where('userId', '==', args.userId),
        where('virtualUserIdFrom', '==', args.userIdFrom),
      );
      const docSnap = await getDocs(q);

      return docSnap.size;
    } catch (e) {
      console.log('error', e);
    }
  },
);
