import { Alert, Box, Typography } from '@mui/material';
import ImportantInfosForm from './ImportantInfosForm';
import Button from '@/common/core/Button';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { setEditMode } from '@/store/customers/aboutClientSlice';
import { convertImportantInfoToImportantInfos } from '@/store/automation/aiSlice';
import TextField from '@mui/material/TextField';

function ImportantInfo() {
  const dispatch = useAppDispatch();
  const { customer } = useAppSelector((state) => state.customerInfos);
  const { editMode } = useAppSelector((state) => state.aboutClient);
  const { loading } = useAppSelector((state) => state.automationAi);
  const setEdit = (value: boolean) => dispatch(setEditMode({ isEdit: value }));

  const handleConvertAction = () => {
    if (!customer?.importantInfo || !customer.id) return;
    dispatch(
      convertImportantInfoToImportantInfos({
        userId: customer.id,
        importantInfo: customer.importantInfo,
      }),
    );
  };

  return (
    <Box>
      <Typography variant="h5">Important client info</Typography>
      <Typography variant="body2">
        For example, food allergies, physical limitations or other info that will help you deliver a
        personalised experience
      </Typography>

      <Box marginTop={3}>
        {loading && (
          <div className="flex">
            <span className="icon-[line-md--loading-twotone-loop] w-5 h-5 text-dark" />
            <p className="mx-2">converting data...</p>
          </div>
        )}
        {customer?.importantInfo && !loading && (
          <div className="w-full">
            <Alert color="warning">
              <Typography variant="body2">
                This user has important infos in the old format. before changing it please convert
                it to the new structure.
              </Typography>
              <Button onClick={handleConvertAction}>Convert to new structure</Button>
            </Alert>
            <div className="mt-2 w-full">
              <TextField disabled multiline fullWidth rows={6} value={customer.importantInfo} />
            </div>
          </div>
        )}
        {!loading && <ImportantInfosForm isOpen={editMode} openEditMode={setEdit} />}
        {!editMode && !loading && !customer?.importantInfo && (
          <Button variant="outlined" onClick={() => setEdit(true)}>
            Edit
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default ImportantInfo;
