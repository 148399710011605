import axios from 'axios';

export const sendReminderMessage = async (message: any) => {
  const res = await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_BACKEND_API_ROOT}/sendReminderMessage`,
    data: {
      message,
    },
  });
  return res.data;
};

export const rejectReminderMessage = async (message: any) => {
  const res = await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_BACKEND_API_ROOT}/rejectReminderMessage`,
    data: {
      message,
    },
  });
  return res.data;
};

export const createCustomReminder = async (message: any) => {
  const res = await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_BACKEND_API_ROOT}/createCustomReminder`,
    data: {
      message,
    },
  });
  return res.data;
};

export const ignoreCustomReminderCreation = async (message: any) => {
  const res = await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_BACKEND_API_ROOT}/ignoreCustomReminderCreation`,
    data: {
      message,
    },
  });
  return res.data;
};
