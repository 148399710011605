import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store';
import { fetchUserReminders, deleteReminder } from '@/store/customers/remindersSlice';
import {
  updateCustomerReminderConfig,
  updateCustomerReminderDays,
} from '@/store/customers/customersSlice';
import { Checkbox, CircularProgress } from '@mui/material';
import { format, formatInTimeZone } from 'date-fns-tz';
import {
  Chip,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Switch,
  Paper,
} from '@mui/material';
import { ReminderStatus, ReminderTypes, Reminder } from '@/types/reminders';
import Button from '@/common/core/Button';
import { EditReminderDialog } from './EditReminderDialog';

const getStatusColor = (status: ReminderStatus) => {
  switch (status) {
    case ReminderStatus.active:
      return 'success';
    case ReminderStatus.inactive:
      return 'default';
    case ReminderStatus.completed:
      return 'primary';
    case ReminderStatus.missed:
      return 'warning';
    case ReminderStatus.rejected:
      return 'error';
    default:
      return 'default';
  }
};

const getReminderTypeLabel = (type: ReminderTypes) => {
  switch (type) {
    case ReminderTypes.firstDay:
      return 'First Day';
    case ReminderTypes.midWeek:
      return 'Mid Week';
    case ReminderTypes.endWeek:
      return 'End Week';
    case ReminderTypes.notable:
      return 'Notable';
    case ReminderTypes.company:
      return 'Company';
    case ReminderTypes.custom:
      return 'Custom';
    case ReminderTypes.behind:
      return 'Behind';
    default:
      return type;
  }
};

const getStatusLabel = (status: ReminderStatus) => {
  switch (status) {
    case ReminderStatus.inactive:
      return 'Upcoming';
    case ReminderStatus.active:
      return 'Ready to Send';
    case ReminderStatus.completed:
      return 'Sent';
    case ReminderStatus.missed:
      return 'Missed';
    case ReminderStatus.rejected:
      return 'Rejected';
    default:
      return status;
  }
};

const ChatReminders = () => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [reminderToDelete, setReminderToDelete] = useState<Reminder | null>(null);
  const [editingReminder, setEditingReminder] = useState<Reminder | null>(null);
  const [showCheckIns, setShowCheckIns] = useState(false);
  const [showCoreReminders, setShowCoreReminders] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const { loading, reminders } = useSelector((state: RootState) => state.reminders);
  const { customer } = useSelector((state: RootState) => state.customerInfos);

  // Initialize switches based on customer config
  useEffect(() => {
    if (customer) {
      setShowCheckIns(!!customer.wantsCheckInReminders);
      setShowCoreReminders(!!customer.wantsCoreReminders);
    }
  }, [customer]);

  const handleCheckInsChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (customer?.id) {
      setShowCheckIns(e.target.checked);
      await dispatch(
        updateCustomerReminderConfig({
          userId: customer.id,
          wantsCheckInReminders: e.target.checked,
        }),
      );
    }
  };

  const handleCoreRemindersChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (customer?.id) {
      setShowCoreReminders(e.target.checked);
      await dispatch(
        updateCustomerReminderConfig({
          userId: customer.id,
          wantsCoreReminders: e.target.checked,
        }),
      );
    }
  };

  // Sort reminders by status (active/inactive first) then by date
  const sortedReminders = [...reminders].sort((a, b) => {
    // First sort by status
    const statusOrder = {
      [ReminderStatus.active]: 0,
      [ReminderStatus.inactive]: 1,
      [ReminderStatus.completed]: 2,
      [ReminderStatus.missed]: 3,
      [ReminderStatus.rejected]: 4,
    };

    const statusDiff = statusOrder[a.status] - statusOrder[b.status];

    // If status is the same, sort by date
    if (statusDiff === 0) {
      const dateA = a.schedule.sendAtUtc?.toDate() || new Date();
      const dateB = b.schedule.sendAtUtc?.toDate() || new Date();
      return dateA.getTime() - dateB.getTime();
    }

    return statusDiff;
  });

  // Modify the filtering logic
  const filteredReminders = sortedReminders.filter((reminder) => {
    if (reminder.reminderType === ReminderTypes.custom) {
      return true; // Always show custom reminders
    }

    const isCheckIn = [
      ReminderTypes.firstDay,
      ReminderTypes.midWeek,
      ReminderTypes.endWeek,
    ].includes(reminder.reminderType);
    const isCoreReminder = [
      ReminderTypes.notable,
      ReminderTypes.company,
      ReminderTypes.behind,
    ].includes(reminder.reminderType);

    return (isCheckIn && showCheckIns) || (isCoreReminder && showCoreReminders);
  });

  const customReminders = filteredReminders.filter((r) => r.reminderType === ReminderTypes.custom);
  const systemReminders = filteredReminders.filter((r) => r.reminderType !== ReminderTypes.custom);

  useEffect(() => {
    if (customer?.id) {
      dispatch(fetchUserReminders(customer.id));
    }
  }, [dispatch, customer?.id]);

  const handleDeleteClick = (reminder: Reminder) => {
    setReminderToDelete(reminder);
    setDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (reminderToDelete && reminderToDelete.id && customer?.id) {
      try {
        await dispatch(
          deleteReminder({
            userId: customer.id,
            reminderId: reminderToDelete.id,
          }),
        ).unwrap();
      } catch (error) {
        console.error('Failed to delete reminder:', error);
      }
    }
    setDeleteModalOpen(false);
    setReminderToDelete(null);
  };

  const handleEditClick = (reminder: Reminder) => {
    setEditingReminder(reminder);
  };

  if (loading) {
    return (
      <div className="h-full w-full flex items-center justify-center">
        <CircularProgress />
      </div>
    );
  }

  const ReminderCard = ({ reminder }: { reminder: Reminder }) => (
    <Box key={reminder.id} className="p-4 border rounded-lg mb-4 shadow-sm relative">
      <div className="flex justify-between items-start mb-2">
        <div>
          <Typography variant="h6">{reminder.name}</Typography>
          {reminder.schedule.sendAtUtc && (
            <div>
              <Typography variant="caption" color="text.secondary">
                <b>{format(reminder.schedule.sendAtUtc.toDate(), 'PPp')}</b>. (Client's time:{' '}
                {formatInTimeZone(reminder.schedule.sendAtUtc.toDate(), reminder.timezone, 'PPp')})
              </Typography>
            </div>
          )}
        </div>
        <div className="flex gap-2">
          <Chip
            label={getReminderTypeLabel(reminder.reminderType)}
            size="small"
            variant="outlined"
          />
          <Chip
            label={getStatusLabel(reminder.status)}
            color={getStatusColor(reminder.status)}
            size="small"
          />
        </div>
      </div>

      <Typography variant="body1" className="mb-2">
        Subject: {reminder.content}
      </Typography>

      <div className="flex justify-between items-center">
        <div className="flex gap-2 flex-wrap">
          <Chip
            label={reminder.schedule.repeating ? 'Repeating' : 'One-time'}
            size="small"
            variant="outlined"
          />
          <Chip
            label={reminder.schedule.nickname.replace('_', ' ')}
            size="small"
            variant="outlined"
          />
          {reminder.schedule.time && (
            <Chip label={reminder.schedule.time} size="small" variant="outlined" />
          )}
        </div>

        {reminder.reminderType === ReminderTypes.custom &&
          (reminder.status === ReminderStatus.active ||
            reminder.status === ReminderStatus.inactive) && (
            <div className="flex gap-2">
              <Button variant="outlined" color="primary" onClick={() => handleEditClick(reminder)}>
                View/Edit
              </Button>
              <Button variant="contained" color="error" onClick={() => handleDeleteClick(reminder)}>
                Delete
              </Button>
            </div>
          )}
      </div>

      {reminder.goals && reminder.goals.length > 0 && (
        <div className="mt-2">
          <Typography variant="caption" className="text-gray-600">
            Goals:
          </Typography>
          <div className="flex gap-1 flex-wrap mt-1">
            {reminder.goals.map((goal: string, index: number) => (
              <Chip key={index} label={goal} size="small" variant="outlined" className="text-xs" />
            ))}
          </div>
        </div>
      )}
    </Box>
  );
  const toggleReminderDay = async (day: number) => {
    if (!customer?.id) return;
    const dayAlreadySet = customer?.remindersDays?.includes(day);
    const otherDays = customer?.remindersDays?.filter((d) => d !== day) || [];
    const update = dayAlreadySet ? otherDays : [...otherDays, day];
    await dispatch(updateCustomerReminderDays({ userId: customer?.id, remindersDays: update }));
  };

  return (
    <div className="h-full w-full flex">
      {/* New Side Panel */}
      <Paper className="w-64 p-4 border-r" elevation={0}>
        <Typography variant="h6" className="mb-4">
          Configuration
        </Typography>
        <div className="flex flex-col gap-2">
          <FormControlLabel
            control={
              <Switch checked={showCheckIns} onChange={handleCheckInsChange} color="primary" />
            }
            label="Check-ins"
          />
          <FormControlLabel
            control={
              <Switch
                checked={showCoreReminders}
                onChange={handleCoreRemindersChange}
                color="primary"
              />
            }
            label="Core Reminders"
          />
          {(showCoreReminders || showCheckIns) && (
            <div>
              Days for reminders:
              <div>
                <Checkbox
                  checked={customer?.remindersDays?.includes(1)}
                  onChange={() => toggleReminderDay(1)}
                />
                Monday
              </div>
              <div>
                <Checkbox
                  checked={customer?.remindersDays?.includes(2)}
                  onChange={() => toggleReminderDay(2)}
                />
                Tuesday
              </div>
              <div>
                <Checkbox
                  checked={customer?.remindersDays?.includes(3)}
                  onChange={() => toggleReminderDay(3)}
                />
                Wednesday
              </div>
              <div>
                <Checkbox
                  checked={customer?.remindersDays?.includes(4)}
                  onChange={() => toggleReminderDay(4)}
                />
                Thursday
              </div>
              <div>
                <Checkbox
                  checked={customer?.remindersDays?.includes(5)}
                  onChange={() => toggleReminderDay(5)}
                />
                Friday
              </div>
              <div>
                <Checkbox
                  checked={customer?.remindersDays?.includes(6)}
                  onChange={() => toggleReminderDay(6)}
                />
                Saturday
              </div>
              <div>
                <Checkbox
                  checked={customer?.remindersDays?.includes(7)}
                  onChange={() => toggleReminderDay(7)}
                />
                Sunday
              </div>
            </div>
          )}
        </div>
      </Paper>

      {/* Main Content */}
      <div className="flex-1 p-4">
        {filteredReminders.length === 0 ? (
          <div className="text-center p-4">No reminders found</div>
        ) : (
          <>
            {customReminders.length > 0 && (
              <div className="mb-8">
                <Typography variant="h6" className="mb-4">
                  Custom Reminders
                </Typography>
                {customReminders.map((reminder) => (
                  <ReminderCard key={reminder.id} reminder={reminder} />
                ))}
              </div>
            )}

            {systemReminders.length > 0 && (
              <div className="mb-8">
                <Typography variant="h6" className="mb-4">
                  System Reminders
                </Typography>
                {systemReminders.map((reminder) => (
                  <ReminderCard key={reminder.id} reminder={reminder} />
                ))}
              </div>
            )}
          </>
        )}
      </div>

      <Dialog
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        aria-labelledby="delete-dialog-title"
      >
        <DialogTitle id="delete-dialog-title">Delete Reminder</DialogTitle>
        <DialogContent>Are you sure you want to delete this reminder?</DialogContent>
        <DialogActions className="flex justify-center gap-2 pb-4">
          <Button onClick={() => setDeleteModalOpen(false)} variant="contained" color="error">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {editingReminder && customer?.id && (
        <EditReminderDialog
          reminder={editingReminder}
          userId={customer.id}
          onClose={() => setEditingReminder(null)}
        />
      )}
    </div>
  );
};

export default ChatReminders;
