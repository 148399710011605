import { configureStore } from '@reduxjs/toolkit';
import coachesReducer from './coaches/coachesSlice';
import customersReducer from './customers/customersSlice';
import weekProgramReducer from './program/weekProgramSlice';
import programActionsReducer from './program/programActionsSlice';
import sendasReducer from './sendAs/sendAsSlice';
import chatReducer from './chat';
import mealFeedbackReducer from './mealFeedback/mealFeedbackSlice';
import bodyProfileReducer from './customers/bodyProfileSlice';
import { clientSearchSlice } from './client-search/clientSearchSlice';
import customerInfosReducer from './customers/customerInfosSlice';
import bgsReducer from './partners/bgsSlice';
import progressFeedbackReducer from './customers/progressFeedbackSlice';
import automationAiReducer from './automation/aiSlice';
import messageQuotedReducer from './chat/quotedMessageSlice';
import progressFaqReducer from './customers/progressFaqSlice';
import savedDraftReducer from './chat/savedDraftSlice';
import focusAreaReducer from './program/focusAreaSlice';
import handoutReducer from './program/handoutSlice';
import messageAnalyzeReducer from './chat/messageAnalyzeSlice';
import onboardingSurveyReducer from './customers/onboardingSurveySlice';
import watcherReducer from './customers/watcherSlice';
import coachAuthReducer from './coaches/coachAuthSlice';
import teamReducer from './teams/teamSlice';
import aboutClientReducer from './customers/aboutClientSlice';
import coPilotReducer from './coPilot/coPilotSlice';
import versionReducer from './version/versionSlice';
import savvySurveyReducer from './savvy/survey';
import sendMessageReducer from './chat/sendMessageSlice';
import remindersReducer from './customers/remindersSlice';

const isDevEnvironment = window.location.hostname === 'localhost';

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    coaches: coachesReducer,
    customers: customersReducer,
    weekProgram: weekProgramReducer,
    programActions: programActionsReducer,
    clientSearch: clientSearchSlice.reducer,
    sendAs: sendasReducer,
    chat: chatReducer,
    mealFeedback: mealFeedbackReducer,
    bodyProfile: bodyProfileReducer,
    customerInfos: customerInfosReducer,
    bgs: bgsReducer,
    progressFeedback: progressFeedbackReducer,
    automationAi: automationAiReducer,
    messageQuoted: messageQuotedReducer,
    progressFaq: progressFaqReducer,
    aboutClient: aboutClientReducer,
    savedDraft: savedDraftReducer,
    focusArea: focusAreaReducer,
    handout: handoutReducer,
    messageAnalyze: messageAnalyzeReducer,
    onboardingSurvey: onboardingSurveyReducer,
    watcher: watcherReducer,
    coachAuth: coachAuthReducer,
    team: teamReducer,
    coPilot: coPilotReducer,
    version: versionReducer,
    savvySurvey: savvySurveyReducer,
    sendMessage: sendMessageReducer,
    reminders: remindersReducer,
  },
  devTools: isDevEnvironment,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type StoreType = typeof store;
